// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/BebasNeue-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/BebasNeue-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/BebasNeue-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/Lato-Light.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/fonts/Lato-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/fonts/Lato-Bold.woff2");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Bebas Neue\";font-style:normal;font-weight:300;src:local(\"Bebas Neue\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"Bebas Neue\";font-style:normal;font-weight:400;src:local(\"Bebas Neue\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"Bebas Neue\";font-style:normal;font-weight:700;src:local(\"Bebas Neue\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"Lato\";font-style:normal;font-weight:300;src:local(\"Lato\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"Lato\";font-style:normal;font-weight:400;src:local(\"Lato\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\")}@font-face{font-display:swap;font-family:\"Lato\";font-style:normal;font-weight:700;src:local(\"Lato\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff2\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
