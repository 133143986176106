var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout"
  }, [_c('Header'), _vm._v(" "), _c('Nuxt')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }