import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _77083efa = () => interopDefault(import('../pages/interest.vue' /* webpackChunkName: "pages/interest" */))
const _57062649 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _821ea770 = () => interopDefault(import('../pages/notifications.vue' /* webpackChunkName: "pages/notifications" */))
const _762f3366 = () => interopDefault(import('../pages/radar.vue' /* webpackChunkName: "pages/radar" */))
const _14af8948 = () => interopDefault(import('../pages/radar/_city/_slug.vue' /* webpackChunkName: "pages/radar/_city/_slug" */))
const _da250dd2 = () => interopDefault(import('../pages/orp-landing.vue' /* webpackChunkName: "pages/orp-landing" */))
const _16a10058 = () => interopDefault(import('../pages/preferences.vue' /* webpackChunkName: "pages/preferences" */))
const _ea2df3b2 = () => interopDefault(import('../pages/referralprogram.vue' /* webpackChunkName: "pages/referralprogram" */))
const _53cee030 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _43b70ee4 = () => interopDefault(import('../pages/suggest.vue' /* webpackChunkName: "pages/suggest" */))
const _d6d52c5c = () => interopDefault(import('../pages/suggest/_id/index.vue' /* webpackChunkName: "pages/suggest/_id/index" */))
const _d1db4682 = () => interopDefault(import('../pages/user/convert.vue' /* webpackChunkName: "pages/user/convert" */))
const _1aa6e6df = () => interopDefault(import('../pages/user/curriculum.vue' /* webpackChunkName: "pages/user/curriculum" */))
const _86dbb7c0 = () => interopDefault(import('../pages/user/dashboard.vue' /* webpackChunkName: "pages/user/dashboard" */))
const _90923c06 = () => interopDefault(import('../pages/user/dashboard/career.vue' /* webpackChunkName: "pages/user/dashboard/career" */))
const _080328e1 = () => interopDefault(import('../pages/user/dashboard/career/home.vue' /* webpackChunkName: "pages/user/dashboard/career/home" */))
const _17275e8f = () => interopDefault(import('../pages/user/dashboard/career/legend.vue' /* webpackChunkName: "pages/user/dashboard/career/legend" */))
const _5e3d8018 = () => interopDefault(import('../pages/user/dashboard/suggested.vue' /* webpackChunkName: "pages/user/dashboard/suggested" */))
const _1c84e8fb = () => interopDefault(import('../pages/user/dashboard/suggested/candidates.vue' /* webpackChunkName: "pages/user/dashboard/suggested/candidates" */))
const _0d2d888f = () => interopDefault(import('../pages/user/dashboard/suggested/positions.vue' /* webpackChunkName: "pages/user/dashboard/suggested/positions" */))
const _0d8eea74 = () => interopDefault(import('../pages/user/notifications.vue' /* webpackChunkName: "pages/user/notifications" */))
const _26a83e0e = () => interopDefault(import('../pages/user/notifications/applications.vue' /* webpackChunkName: "pages/user/notifications/applications" */))
const _7bc2da68 = () => interopDefault(import('../pages/user/notifications/prizes.vue' /* webpackChunkName: "pages/user/notifications/prizes" */))
const _57ada570 = () => interopDefault(import('../pages/user/notifications/suggested.vue' /* webpackChunkName: "pages/user/notifications/suggested" */))
const _3ffcc5c7 = () => interopDefault(import('../pages/user/settings.vue' /* webpackChunkName: "pages/user/settings" */))
const _03bea19b = () => interopDefault(import('../pages/user/settings/applications.vue' /* webpackChunkName: "pages/user/settings/applications" */))
const _37e9bad1 = () => interopDefault(import('../pages/user/settings/avatar.vue' /* webpackChunkName: "pages/user/settings/avatar" */))
const _1f43b0a2 = () => interopDefault(import('../pages/user/settings/data.vue' /* webpackChunkName: "pages/user/settings/data" */))
const _181ba96c = () => interopDefault(import('../pages/user/settings/my-cv.vue' /* webpackChunkName: "pages/user/settings/my-cv" */))
const _1d177254 = () => interopDefault(import('../pages/user/settings/my-referral.vue' /* webpackChunkName: "pages/user/settings/my-referral" */))
const _7b84eb13 = () => interopDefault(import('../pages/user/settings/password.vue' /* webpackChunkName: "pages/user/settings/password" */))
const _771a53ee = () => interopDefault(import('../pages/user/settings/personality-test.vue' /* webpackChunkName: "pages/user/settings/personality-test" */))
const _8a51df3e = () => interopDefault(import('../pages/user/settings/profile.vue' /* webpackChunkName: "pages/user/settings/profile" */))
const _861c4cd8 = () => interopDefault(import('../pages/user/settings/redeem.vue' /* webpackChunkName: "pages/user/settings/redeem" */))
const _5ab2b694 = () => interopDefault(import('../pages/user/test.vue' /* webpackChunkName: "pages/user/test" */))
const _98cb0eac = () => interopDefault(import('../pages/user/results/test/_slug.vue' /* webpackChunkName: "pages/user/results/test/_slug" */))
const _2a577e6a = () => interopDefault(import('../pages/all/_wp.vue' /* webpackChunkName: "pages/all/_wp" */))
const _77dcc5cd = () => interopDefault(import('../pages/application/_id/interview.vue' /* webpackChunkName: "pages/application/_id/interview" */))
const _74fabe73 = () => interopDefault(import('../pages/application/_id/steps.vue' /* webpackChunkName: "pages/application/_id/steps" */))
const _386371e2 = () => interopDefault(import('../pages/application/_id/typeform.vue' /* webpackChunkName: "pages/application/_id/typeform" */))
const _32d31600 = () => interopDefault(import('../pages/application/_id/candidate/confirm.vue' /* webpackChunkName: "pages/application/_id/candidate/confirm" */))
const _19c4ee0f = () => interopDefault(import('../pages/application/_id/candidate/reject.vue' /* webpackChunkName: "pages/application/_id/candidate/reject" */))
const _667293c2 = () => interopDefault(import('../pages/dtw/_slug.vue' /* webpackChunkName: "pages/dtw/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de",
    component: _e542f99c,
    name: "index___de"
  }, {
    path: "/en",
    component: _e542f99c,
    name: "index___en"
  }, {
    path: "/es",
    component: _e542f99c,
    name: "index___es"
  }, {
    path: "/fr",
    component: _e542f99c,
    name: "index___fr"
  }, {
    path: "/interest",
    component: _77083efa,
    name: "interest___it"
  }, {
    path: "/login",
    component: _57062649,
    name: "login___it"
  }, {
    path: "/notifications",
    component: _821ea770,
    name: "notifications___it"
  }, {
    path: "/offerte-lavoro",
    component: _762f3366,
    name: "radar___it",
    children: [{
      path: "/offerte-lavoro/:city/:slug",
      component: _14af8948,
      name: "radar-city-slug___it"
    }]
  }, {
    path: "/orp-landing",
    component: _da250dd2,
    name: "orp-landing___it"
  }, {
    path: "/preferences",
    component: _16a10058,
    name: "preferences___it"
  }, {
    path: "/pt",
    component: _e542f99c,
    name: "index___pt"
  }, {
    path: "/referralprogram",
    component: _ea2df3b2,
    name: "referralprogram___it"
  }, {
    path: "/signup",
    component: _53cee030,
    name: "signup___it"
  }, {
    path: "/suggest",
    component: _43b70ee4,
    name: "suggest___it",
    children: [{
      path: ":id",
      component: _d6d52c5c,
      name: "suggest-id___it"
    }]
  }, {
    path: "/de/interest",
    component: _77083efa,
    name: "interest___de"
  }, {
    path: "/de/job-angebote",
    component: _762f3366,
    name: "radar___de",
    children: [{
      path: "/de/job-angebote/:city/:slug",
      component: _14af8948,
      name: "radar-city-slug___de"
    }]
  }, {
    path: "/de/login",
    component: _57062649,
    name: "login___de"
  }, {
    path: "/de/notifications",
    component: _821ea770,
    name: "notifications___de"
  }, {
    path: "/de/orp-landing",
    component: _da250dd2,
    name: "orp-landing___de"
  }, {
    path: "/de/preferences",
    component: _16a10058,
    name: "preferences___de"
  }, {
    path: "/de/referralprogram",
    component: _ea2df3b2,
    name: "referralprogram___de"
  }, {
    path: "/de/signup",
    component: _53cee030,
    name: "signup___de"
  }, {
    path: "/de/suggest",
    component: _43b70ee4,
    name: "suggest___de",
    children: [{
      path: ":id",
      component: _d6d52c5c,
      name: "suggest-id___de"
    }]
  }, {
    path: "/en/interest",
    component: _77083efa,
    name: "interest___en"
  }, {
    path: "/en/job-offers",
    component: _762f3366,
    name: "radar___en",
    children: [{
      path: "/en/job-offers/:city/:slug",
      component: _14af8948,
      name: "radar-city-slug___en"
    }]
  }, {
    path: "/en/login",
    component: _57062649,
    name: "login___en"
  }, {
    path: "/en/notifications",
    component: _821ea770,
    name: "notifications___en"
  }, {
    path: "/en/orp-landing",
    component: _da250dd2,
    name: "orp-landing___en"
  }, {
    path: "/en/preferences",
    component: _16a10058,
    name: "preferences___en"
  }, {
    path: "/en/referralprogram",
    component: _ea2df3b2,
    name: "referralprogram___en"
  }, {
    path: "/en/signup",
    component: _53cee030,
    name: "signup___en"
  }, {
    path: "/en/suggest",
    component: _43b70ee4,
    name: "suggest___en",
    children: [{
      path: ":id",
      component: _d6d52c5c,
      name: "suggest-id___en"
    }]
  }, {
    path: "/es/interest",
    component: _77083efa,
    name: "interest___es"
  }, {
    path: "/es/login",
    component: _57062649,
    name: "login___es"
  }, {
    path: "/es/notifications",
    component: _821ea770,
    name: "notifications___es"
  }, {
    path: "/es/ofertas-empleo",
    component: _762f3366,
    name: "radar___es",
    children: [{
      path: "/es/ofertas-empleo/:city/:slug",
      component: _14af8948,
      name: "radar-city-slug___es"
    }]
  }, {
    path: "/es/orp-landing",
    component: _da250dd2,
    name: "orp-landing___es"
  }, {
    path: "/es/preferences",
    component: _16a10058,
    name: "preferences___es"
  }, {
    path: "/es/referralprogram",
    component: _ea2df3b2,
    name: "referralprogram___es"
  }, {
    path: "/es/signup",
    component: _53cee030,
    name: "signup___es"
  }, {
    path: "/es/suggest",
    component: _43b70ee4,
    name: "suggest___es",
    children: [{
      path: ":id",
      component: _d6d52c5c,
      name: "suggest-id___es"
    }]
  }, {
    path: "/fr/interest",
    component: _77083efa,
    name: "interest___fr"
  }, {
    path: "/fr/login",
    component: _57062649,
    name: "login___fr"
  }, {
    path: "/fr/notifications",
    component: _821ea770,
    name: "notifications___fr"
  }, {
    path: "/fr/offres-emploi",
    component: _762f3366,
    name: "radar___fr",
    children: [{
      path: "/fr/offres-emploi/:city/:slug",
      component: _14af8948,
      name: "radar-city-slug___fr"
    }]
  }, {
    path: "/fr/orp-landing",
    component: _da250dd2,
    name: "orp-landing___fr"
  }, {
    path: "/fr/preferences",
    component: _16a10058,
    name: "preferences___fr"
  }, {
    path: "/fr/referralprogram",
    component: _ea2df3b2,
    name: "referralprogram___fr"
  }, {
    path: "/fr/signup",
    component: _53cee030,
    name: "signup___fr"
  }, {
    path: "/fr/suggest",
    component: _43b70ee4,
    name: "suggest___fr",
    children: [{
      path: ":id",
      component: _d6d52c5c,
      name: "suggest-id___fr"
    }]
  }, {
    path: "/pt/interest",
    component: _77083efa,
    name: "interest___pt"
  }, {
    path: "/pt/login",
    component: _57062649,
    name: "login___pt"
  }, {
    path: "/pt/notifications",
    component: _821ea770,
    name: "notifications___pt"
  }, {
    path: "/pt/ofertas-emprego",
    component: _762f3366,
    name: "radar___pt",
    children: [{
      path: "/pt/ofertas-emprego/:city/:slug",
      component: _14af8948,
      name: "radar-city-slug___pt"
    }]
  }, {
    path: "/pt/orp-landing",
    component: _da250dd2,
    name: "orp-landing___pt"
  }, {
    path: "/pt/preferences",
    component: _16a10058,
    name: "preferences___pt"
  }, {
    path: "/pt/referralprogram",
    component: _ea2df3b2,
    name: "referralprogram___pt"
  }, {
    path: "/pt/signup",
    component: _53cee030,
    name: "signup___pt"
  }, {
    path: "/pt/suggest",
    component: _43b70ee4,
    name: "suggest___pt",
    children: [{
      path: ":id",
      component: _d6d52c5c,
      name: "suggest-id___pt"
    }]
  }, {
    path: "/user/convert",
    component: _d1db4682,
    name: "user-convert___it"
  }, {
    path: "/user/curriculum",
    component: _1aa6e6df,
    name: "user-curriculum___it"
  }, {
    path: "/user/dashboard",
    component: _86dbb7c0,
    name: "user-dashboard___it",
    children: [{
      path: "career",
      component: _90923c06,
      name: "user-dashboard-career___it",
      children: [{
        path: "home",
        component: _080328e1,
        name: "user-dashboard-career-home___it"
      }, {
        path: "legend",
        component: _17275e8f,
        name: "user-dashboard-career-legend___it"
      }]
    }, {
      path: "suggested",
      component: _5e3d8018,
      name: "user-dashboard-suggested___it",
      children: [{
        path: "candidates",
        component: _1c84e8fb,
        name: "user-dashboard-suggested-candidates___it"
      }, {
        path: "positions",
        component: _0d2d888f,
        name: "user-dashboard-suggested-positions___it"
      }]
    }]
  }, {
    path: "/user/notifications",
    component: _0d8eea74,
    name: "user-notifications___it",
    children: [{
      path: "applications",
      component: _26a83e0e,
      name: "user-notifications-applications___it"
    }, {
      path: "prizes",
      component: _7bc2da68,
      name: "user-notifications-prizes___it"
    }, {
      path: "suggested",
      component: _57ada570,
      name: "user-notifications-suggested___it"
    }]
  }, {
    path: "/user/settings",
    component: _3ffcc5c7,
    name: "user-settings___it",
    children: [{
      path: "applications",
      component: _03bea19b,
      name: "user-settings-applications___it"
    }, {
      path: "avatar",
      component: _37e9bad1,
      name: "user-settings-avatar___it"
    }, {
      path: "data",
      component: _1f43b0a2,
      name: "user-settings-data___it"
    }, {
      path: "my-cv",
      component: _181ba96c,
      name: "user-settings-my-cv___it"
    }, {
      path: "my-referral",
      component: _1d177254,
      name: "user-settings-my-referral___it"
    }, {
      path: "password",
      component: _7b84eb13,
      name: "user-settings-password___it"
    }, {
      path: "personality-test",
      component: _771a53ee,
      name: "user-settings-personality-test___it"
    }, {
      path: "profile",
      component: _8a51df3e,
      name: "user-settings-profile___it"
    }, {
      path: "redeem",
      component: _861c4cd8,
      name: "user-settings-redeem___it"
    }]
  }, {
    path: "/user/test",
    component: _5ab2b694,
    name: "user-test___it"
  }, {
    path: "/de/user/convert",
    component: _d1db4682,
    name: "user-convert___de"
  }, {
    path: "/de/user/curriculum",
    component: _1aa6e6df,
    name: "user-curriculum___de"
  }, {
    path: "/de/user/dashboard",
    component: _86dbb7c0,
    name: "user-dashboard___de",
    children: [{
      path: "career",
      component: _90923c06,
      name: "user-dashboard-career___de",
      children: [{
        path: "home",
        component: _080328e1,
        name: "user-dashboard-career-home___de"
      }, {
        path: "legend",
        component: _17275e8f,
        name: "user-dashboard-career-legend___de"
      }]
    }, {
      path: "suggested",
      component: _5e3d8018,
      name: "user-dashboard-suggested___de",
      children: [{
        path: "candidates",
        component: _1c84e8fb,
        name: "user-dashboard-suggested-candidates___de"
      }, {
        path: "positions",
        component: _0d2d888f,
        name: "user-dashboard-suggested-positions___de"
      }]
    }]
  }, {
    path: "/de/user/notifications",
    component: _0d8eea74,
    name: "user-notifications___de",
    children: [{
      path: "applications",
      component: _26a83e0e,
      name: "user-notifications-applications___de"
    }, {
      path: "prizes",
      component: _7bc2da68,
      name: "user-notifications-prizes___de"
    }, {
      path: "suggested",
      component: _57ada570,
      name: "user-notifications-suggested___de"
    }]
  }, {
    path: "/de/user/settings",
    component: _3ffcc5c7,
    name: "user-settings___de",
    children: [{
      path: "applications",
      component: _03bea19b,
      name: "user-settings-applications___de"
    }, {
      path: "avatar",
      component: _37e9bad1,
      name: "user-settings-avatar___de"
    }, {
      path: "data",
      component: _1f43b0a2,
      name: "user-settings-data___de"
    }, {
      path: "my-cv",
      component: _181ba96c,
      name: "user-settings-my-cv___de"
    }, {
      path: "my-referral",
      component: _1d177254,
      name: "user-settings-my-referral___de"
    }, {
      path: "password",
      component: _7b84eb13,
      name: "user-settings-password___de"
    }, {
      path: "personality-test",
      component: _771a53ee,
      name: "user-settings-personality-test___de"
    }, {
      path: "profile",
      component: _8a51df3e,
      name: "user-settings-profile___de"
    }, {
      path: "redeem",
      component: _861c4cd8,
      name: "user-settings-redeem___de"
    }]
  }, {
    path: "/de/user/test",
    component: _5ab2b694,
    name: "user-test___de"
  }, {
    path: "/en/user/convert",
    component: _d1db4682,
    name: "user-convert___en"
  }, {
    path: "/en/user/curriculum",
    component: _1aa6e6df,
    name: "user-curriculum___en"
  }, {
    path: "/en/user/dashboard",
    component: _86dbb7c0,
    name: "user-dashboard___en",
    children: [{
      path: "career",
      component: _90923c06,
      name: "user-dashboard-career___en",
      children: [{
        path: "home",
        component: _080328e1,
        name: "user-dashboard-career-home___en"
      }, {
        path: "legend",
        component: _17275e8f,
        name: "user-dashboard-career-legend___en"
      }]
    }, {
      path: "suggested",
      component: _5e3d8018,
      name: "user-dashboard-suggested___en",
      children: [{
        path: "candidates",
        component: _1c84e8fb,
        name: "user-dashboard-suggested-candidates___en"
      }, {
        path: "positions",
        component: _0d2d888f,
        name: "user-dashboard-suggested-positions___en"
      }]
    }]
  }, {
    path: "/en/user/notifications",
    component: _0d8eea74,
    name: "user-notifications___en",
    children: [{
      path: "applications",
      component: _26a83e0e,
      name: "user-notifications-applications___en"
    }, {
      path: "prizes",
      component: _7bc2da68,
      name: "user-notifications-prizes___en"
    }, {
      path: "suggested",
      component: _57ada570,
      name: "user-notifications-suggested___en"
    }]
  }, {
    path: "/en/user/settings",
    component: _3ffcc5c7,
    name: "user-settings___en",
    children: [{
      path: "applications",
      component: _03bea19b,
      name: "user-settings-applications___en"
    }, {
      path: "avatar",
      component: _37e9bad1,
      name: "user-settings-avatar___en"
    }, {
      path: "data",
      component: _1f43b0a2,
      name: "user-settings-data___en"
    }, {
      path: "my-cv",
      component: _181ba96c,
      name: "user-settings-my-cv___en"
    }, {
      path: "my-referral",
      component: _1d177254,
      name: "user-settings-my-referral___en"
    }, {
      path: "password",
      component: _7b84eb13,
      name: "user-settings-password___en"
    }, {
      path: "personality-test",
      component: _771a53ee,
      name: "user-settings-personality-test___en"
    }, {
      path: "profile",
      component: _8a51df3e,
      name: "user-settings-profile___en"
    }, {
      path: "redeem",
      component: _861c4cd8,
      name: "user-settings-redeem___en"
    }]
  }, {
    path: "/en/user/test",
    component: _5ab2b694,
    name: "user-test___en"
  }, {
    path: "/es/user/convert",
    component: _d1db4682,
    name: "user-convert___es"
  }, {
    path: "/es/user/curriculum",
    component: _1aa6e6df,
    name: "user-curriculum___es"
  }, {
    path: "/es/user/dashboard",
    component: _86dbb7c0,
    name: "user-dashboard___es",
    children: [{
      path: "career",
      component: _90923c06,
      name: "user-dashboard-career___es",
      children: [{
        path: "home",
        component: _080328e1,
        name: "user-dashboard-career-home___es"
      }, {
        path: "legend",
        component: _17275e8f,
        name: "user-dashboard-career-legend___es"
      }]
    }, {
      path: "suggested",
      component: _5e3d8018,
      name: "user-dashboard-suggested___es",
      children: [{
        path: "candidates",
        component: _1c84e8fb,
        name: "user-dashboard-suggested-candidates___es"
      }, {
        path: "positions",
        component: _0d2d888f,
        name: "user-dashboard-suggested-positions___es"
      }]
    }]
  }, {
    path: "/es/user/notifications",
    component: _0d8eea74,
    name: "user-notifications___es",
    children: [{
      path: "applications",
      component: _26a83e0e,
      name: "user-notifications-applications___es"
    }, {
      path: "prizes",
      component: _7bc2da68,
      name: "user-notifications-prizes___es"
    }, {
      path: "suggested",
      component: _57ada570,
      name: "user-notifications-suggested___es"
    }]
  }, {
    path: "/es/user/settings",
    component: _3ffcc5c7,
    name: "user-settings___es",
    children: [{
      path: "applications",
      component: _03bea19b,
      name: "user-settings-applications___es"
    }, {
      path: "avatar",
      component: _37e9bad1,
      name: "user-settings-avatar___es"
    }, {
      path: "data",
      component: _1f43b0a2,
      name: "user-settings-data___es"
    }, {
      path: "my-cv",
      component: _181ba96c,
      name: "user-settings-my-cv___es"
    }, {
      path: "my-referral",
      component: _1d177254,
      name: "user-settings-my-referral___es"
    }, {
      path: "password",
      component: _7b84eb13,
      name: "user-settings-password___es"
    }, {
      path: "personality-test",
      component: _771a53ee,
      name: "user-settings-personality-test___es"
    }, {
      path: "profile",
      component: _8a51df3e,
      name: "user-settings-profile___es"
    }, {
      path: "redeem",
      component: _861c4cd8,
      name: "user-settings-redeem___es"
    }]
  }, {
    path: "/es/user/test",
    component: _5ab2b694,
    name: "user-test___es"
  }, {
    path: "/fr/user/convert",
    component: _d1db4682,
    name: "user-convert___fr"
  }, {
    path: "/fr/user/curriculum",
    component: _1aa6e6df,
    name: "user-curriculum___fr"
  }, {
    path: "/fr/user/dashboard",
    component: _86dbb7c0,
    name: "user-dashboard___fr",
    children: [{
      path: "career",
      component: _90923c06,
      name: "user-dashboard-career___fr",
      children: [{
        path: "home",
        component: _080328e1,
        name: "user-dashboard-career-home___fr"
      }, {
        path: "legend",
        component: _17275e8f,
        name: "user-dashboard-career-legend___fr"
      }]
    }, {
      path: "suggested",
      component: _5e3d8018,
      name: "user-dashboard-suggested___fr",
      children: [{
        path: "candidates",
        component: _1c84e8fb,
        name: "user-dashboard-suggested-candidates___fr"
      }, {
        path: "positions",
        component: _0d2d888f,
        name: "user-dashboard-suggested-positions___fr"
      }]
    }]
  }, {
    path: "/fr/user/notifications",
    component: _0d8eea74,
    name: "user-notifications___fr",
    children: [{
      path: "applications",
      component: _26a83e0e,
      name: "user-notifications-applications___fr"
    }, {
      path: "prizes",
      component: _7bc2da68,
      name: "user-notifications-prizes___fr"
    }, {
      path: "suggested",
      component: _57ada570,
      name: "user-notifications-suggested___fr"
    }]
  }, {
    path: "/fr/user/settings",
    component: _3ffcc5c7,
    name: "user-settings___fr",
    children: [{
      path: "applications",
      component: _03bea19b,
      name: "user-settings-applications___fr"
    }, {
      path: "avatar",
      component: _37e9bad1,
      name: "user-settings-avatar___fr"
    }, {
      path: "data",
      component: _1f43b0a2,
      name: "user-settings-data___fr"
    }, {
      path: "my-cv",
      component: _181ba96c,
      name: "user-settings-my-cv___fr"
    }, {
      path: "my-referral",
      component: _1d177254,
      name: "user-settings-my-referral___fr"
    }, {
      path: "password",
      component: _7b84eb13,
      name: "user-settings-password___fr"
    }, {
      path: "personality-test",
      component: _771a53ee,
      name: "user-settings-personality-test___fr"
    }, {
      path: "profile",
      component: _8a51df3e,
      name: "user-settings-profile___fr"
    }, {
      path: "redeem",
      component: _861c4cd8,
      name: "user-settings-redeem___fr"
    }]
  }, {
    path: "/fr/user/test",
    component: _5ab2b694,
    name: "user-test___fr"
  }, {
    path: "/pt/user/convert",
    component: _d1db4682,
    name: "user-convert___pt"
  }, {
    path: "/pt/user/curriculum",
    component: _1aa6e6df,
    name: "user-curriculum___pt"
  }, {
    path: "/pt/user/dashboard",
    component: _86dbb7c0,
    name: "user-dashboard___pt",
    children: [{
      path: "career",
      component: _90923c06,
      name: "user-dashboard-career___pt",
      children: [{
        path: "home",
        component: _080328e1,
        name: "user-dashboard-career-home___pt"
      }, {
        path: "legend",
        component: _17275e8f,
        name: "user-dashboard-career-legend___pt"
      }]
    }, {
      path: "suggested",
      component: _5e3d8018,
      name: "user-dashboard-suggested___pt",
      children: [{
        path: "candidates",
        component: _1c84e8fb,
        name: "user-dashboard-suggested-candidates___pt"
      }, {
        path: "positions",
        component: _0d2d888f,
        name: "user-dashboard-suggested-positions___pt"
      }]
    }]
  }, {
    path: "/pt/user/notifications",
    component: _0d8eea74,
    name: "user-notifications___pt",
    children: [{
      path: "applications",
      component: _26a83e0e,
      name: "user-notifications-applications___pt"
    }, {
      path: "prizes",
      component: _7bc2da68,
      name: "user-notifications-prizes___pt"
    }, {
      path: "suggested",
      component: _57ada570,
      name: "user-notifications-suggested___pt"
    }]
  }, {
    path: "/pt/user/settings",
    component: _3ffcc5c7,
    name: "user-settings___pt",
    children: [{
      path: "applications",
      component: _03bea19b,
      name: "user-settings-applications___pt"
    }, {
      path: "avatar",
      component: _37e9bad1,
      name: "user-settings-avatar___pt"
    }, {
      path: "data",
      component: _1f43b0a2,
      name: "user-settings-data___pt"
    }, {
      path: "my-cv",
      component: _181ba96c,
      name: "user-settings-my-cv___pt"
    }, {
      path: "my-referral",
      component: _1d177254,
      name: "user-settings-my-referral___pt"
    }, {
      path: "password",
      component: _7b84eb13,
      name: "user-settings-password___pt"
    }, {
      path: "personality-test",
      component: _771a53ee,
      name: "user-settings-personality-test___pt"
    }, {
      path: "profile",
      component: _8a51df3e,
      name: "user-settings-profile___pt"
    }, {
      path: "redeem",
      component: _861c4cd8,
      name: "user-settings-redeem___pt"
    }]
  }, {
    path: "/pt/user/test",
    component: _5ab2b694,
    name: "user-test___pt"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index___it"
  }, {
    path: "/de/user/results/test/:slug?",
    component: _98cb0eac,
    name: "user-results-test-slug___de"
  }, {
    path: "/en/user/results/test/:slug?",
    component: _98cb0eac,
    name: "user-results-test-slug___en"
  }, {
    path: "/es/user/results/test/:slug?",
    component: _98cb0eac,
    name: "user-results-test-slug___es"
  }, {
    path: "/fr/user/results/test/:slug?",
    component: _98cb0eac,
    name: "user-results-test-slug___fr"
  }, {
    path: "/pt/user/results/test/:slug?",
    component: _98cb0eac,
    name: "user-results-test-slug___pt"
  }, {
    path: "/user/results/test/:slug?",
    component: _98cb0eac,
    name: "user-results-test-slug___it"
  }, {
    path: "/de/all/:wp?",
    component: _2a577e6a,
    name: "all-wp___de"
  }, {
    path: "/de/job-angebote/:city",
    component: _762f3366,
    name: "radar-city___de"
  }, {
    path: "/en/all/:wp?",
    component: _2a577e6a,
    name: "all-wp___en"
  }, {
    path: "/en/job-offers/:city",
    component: _762f3366,
    name: "radar-city___en"
  }, {
    path: "/es/all/:wp?",
    component: _2a577e6a,
    name: "all-wp___es"
  }, {
    path: "/es/ofertas-empleo/:city",
    component: _762f3366,
    name: "radar-city___es"
  }, {
    path: "/fr/all/:wp?",
    component: _2a577e6a,
    name: "all-wp___fr"
  }, {
    path: "/fr/offres-emploi/:city",
    component: _762f3366,
    name: "radar-city___fr"
  }, {
    path: "/pt/all/:wp?",
    component: _2a577e6a,
    name: "all-wp___pt"
  }, {
    path: "/pt/ofertas-emprego/:city",
    component: _762f3366,
    name: "radar-city___pt"
  }, {
    path: "/de/application/:id?/interview",
    component: _77dcc5cd,
    name: "application-id-interview___de"
  }, {
    path: "/de/application/:id?/steps",
    component: _74fabe73,
    name: "application-id-steps___de"
  }, {
    path: "/de/application/:id?/typeform",
    component: _386371e2,
    name: "application-id-typeform___de"
  }, {
    path: "/en/application/:id?/interview",
    component: _77dcc5cd,
    name: "application-id-interview___en"
  }, {
    path: "/en/application/:id?/steps",
    component: _74fabe73,
    name: "application-id-steps___en"
  }, {
    path: "/en/application/:id?/typeform",
    component: _386371e2,
    name: "application-id-typeform___en"
  }, {
    path: "/es/application/:id?/interview",
    component: _77dcc5cd,
    name: "application-id-interview___es"
  }, {
    path: "/es/application/:id?/steps",
    component: _74fabe73,
    name: "application-id-steps___es"
  }, {
    path: "/es/application/:id?/typeform",
    component: _386371e2,
    name: "application-id-typeform___es"
  }, {
    path: "/fr/application/:id?/interview",
    component: _77dcc5cd,
    name: "application-id-interview___fr"
  }, {
    path: "/fr/application/:id?/steps",
    component: _74fabe73,
    name: "application-id-steps___fr"
  }, {
    path: "/fr/application/:id?/typeform",
    component: _386371e2,
    name: "application-id-typeform___fr"
  }, {
    path: "/pt/application/:id?/interview",
    component: _77dcc5cd,
    name: "application-id-interview___pt"
  }, {
    path: "/pt/application/:id?/steps",
    component: _74fabe73,
    name: "application-id-steps___pt"
  }, {
    path: "/pt/application/:id?/typeform",
    component: _386371e2,
    name: "application-id-typeform___pt"
  }, {
    path: "/de/application/:id?/candidate/confirm",
    component: _32d31600,
    name: "application-id-candidate-confirm___de"
  }, {
    path: "/de/application/:id?/candidate/reject",
    component: _19c4ee0f,
    name: "application-id-candidate-reject___de"
  }, {
    path: "/en/application/:id?/candidate/confirm",
    component: _32d31600,
    name: "application-id-candidate-confirm___en"
  }, {
    path: "/en/application/:id?/candidate/reject",
    component: _19c4ee0f,
    name: "application-id-candidate-reject___en"
  }, {
    path: "/es/application/:id?/candidate/confirm",
    component: _32d31600,
    name: "application-id-candidate-confirm___es"
  }, {
    path: "/es/application/:id?/candidate/reject",
    component: _19c4ee0f,
    name: "application-id-candidate-reject___es"
  }, {
    path: "/fr/application/:id?/candidate/confirm",
    component: _32d31600,
    name: "application-id-candidate-confirm___fr"
  }, {
    path: "/fr/application/:id?/candidate/reject",
    component: _19c4ee0f,
    name: "application-id-candidate-reject___fr"
  }, {
    path: "/pt/application/:id?/candidate/confirm",
    component: _32d31600,
    name: "application-id-candidate-confirm___pt"
  }, {
    path: "/pt/application/:id?/candidate/reject",
    component: _19c4ee0f,
    name: "application-id-candidate-reject___pt"
  }, {
    path: "/all/:wp?",
    component: _2a577e6a,
    name: "all-wp___it"
  }, {
    path: "/dtw/:slug?",
    component: _667293c2,
    name: "dtw-slug___it"
  }, {
    path: "/offerte-lavoro/:city",
    component: _762f3366,
    name: "radar-city___it"
  }, {
    path: "/application/:id?/interview",
    component: _77dcc5cd,
    name: "application-id-interview___it"
  }, {
    path: "/application/:id?/steps",
    component: _74fabe73,
    name: "application-id-steps___it"
  }, {
    path: "/application/:id?/typeform",
    component: _386371e2,
    name: "application-id-typeform___it"
  }, {
    path: "/application/:id?/candidate/confirm",
    component: _32d31600,
    name: "application-id-candidate-confirm___it"
  }, {
    path: "/application/:id?/candidate/reject",
    component: _19c4ee0f,
    name: "application-id-candidate-reject___it"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
