
export default {
  props: {
    display: { type: Boolean, default: false },
    title: { type: String, default: '' },
    error: { type: Object, default: () => null }
  },
  computed: {
    localDisplay: {
      get() {
        return this.display
      },
      set(value) {
        this.$emit('update:display', value)
      }
    },
    getErrorDescription() {
      console.log(this.error)
      let message = ''
      const errorCode = this.error?.response?.data?.messageCode
      const id = this.error?.response?.data?.messageId
      if (errorCode) {
        switch (errorCode) {
        case 'EXC-000':
          if (this.error.status === 500) {
            message = 'Errore default'
            break
          }
          if (this.error.status === 406) {
            message = this.$t('error.unauthorized-not-logged')
            break
          }
        }
      } else if (this.error && this.error.status) {
        switch (this.error.status) {
        case 502:
          message = this.$t('error.badGateway')
          break
        case 404:
          message = this.$t('error.noPage')
          break
        case 406:
          if (errorCode === 'EXC-000') {
            message = this.$t('error.tryDifferentBrowser')
          }
          break
        default:
          message = this.$t('error.generic')
          break
        }
      } else {
        message = this.$t('error.generic')
      }
      if (!message) {
        message = this.$t('error.generic')
      }
      if (id) {
        message += '<p><i>' + id + '</i></p>'
      }
      return message
    }
  },
  methods: {
    hideModal() {
      this.localDisplay = false
    }
  }
}
