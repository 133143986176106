import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faPlay, faChevronLeft, faChevronRight, faTh, faSearch, faBell, faLongArrowAltLeft, faLongArrowAltRight, faEye, faListUl, faBriefcase, faPaperclip, faFileAlt, faFileUpload, faTimes, faTrash, faEnvelope, faLock, faEyeSlash, faCheck, faQuestion, faExclamationTriangle, faExternalLinkAlt, faTrophy, faStarSharp, faPeopleArrowsLeftRight, faUser, faLink, faSliders, faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faUserCircle, faCheckCircle, faCalendar, faQuestionCircle, faFile } from '@fortawesome/free-regular-svg-icons'
import { faStarSharp as falStarSharp } from '@fortawesome/pro-light-svg-icons'
import { faFacebookF, faFacebookMessenger, faWhatsapp, faTelegram, faFacebook, faLinkedin, faInstagram, faTwitter, faTiktok, faYoutube, faApple } from '@fortawesome/free-brands-svg-icons'

// This is important, we are going to let Nuxt.js worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin. See other examples for how you
// can add other styles or just individual icons.
library.add(faPlay, faChevronLeft, faChevronRight, faTh, faSearch, faBell, faLongArrowAltLeft, faLongArrowAltRight, faEye, faListUl, faBriefcase, faFileAlt, faPaperclip, faFileUpload, faTimes, faTrash, faEnvelope, faLock, faEyeSlash, faCheck, faQuestion, faExclamationTriangle, faExternalLinkAlt, faUserCircle, faCheckCircle, faCalendar, faQuestionCircle, faFacebookF, faFacebookMessenger, faWhatsapp, faTelegram, faFacebook, faTrophy, faStarSharp, falStarSharp, faFile, faPeopleArrowsLeftRight, faUser, faLink, faLinkedin, faInstagram, faTwitter, faTiktok, faYoutube, faSliders, faXmark, faApple)

// Register the component globally
// eslint-disable-next-line vue/component-definition-name-casing
Vue.component('fa', FontAwesomeIcon)
