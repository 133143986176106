import Vue from 'vue'

import './fonts.css'

import { head } from '#booster/plugins/vFont/head'
import vFont from '#booster/plugins/vFont'
import FontList from '#booster/classes/FontList'
import { isSupportedBrowser } from '#booster/utils/browser';
import globals from '#booster/utils/globals';
import LoadingSpinner from '#booster/components/BoosterImage/classes/LoadingSpinner';

Vue.use(vFont)

const booster = Object.freeze({
  head: () => console.error('$booster.head() is not available in context'),
  crossorigin: "anonymous",
  isBrowserSupported: () => isSupportedBrowser({regex: new RegExp("Edge?\u005C\u002F(1{2}[2-9]|1[2-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Firefox\u005C\u002F(10[2-9]|1[1-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Chrom(ium|e)\u005C\u002F(109|1[1-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|(Maci|X1{2}).+ Version\u005C\u002F(15\u005C.([6-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+)([,.]\u005Cd+|)( \u005C(\u005Cw+\u005C)|)( Mobile\u005C\u002F\u005Cw+|) Safari\u005C\u002F|Chrome.+OPR\u005C\u002F(9[89]|\u005Cd{3,})\u005C.\u005Cd+\u005C.\u005Cd+|(CPU[ +]OS|iPhone[ +]OS|CPU[ +]iPhone|CPU IPhone OS|CPU iPad OS)[ +]+(15[._]([6-9]|\u005Cd{2,})|(1[6-9]|[2-9]\u005Cd|\u005Cd{3,})[._]\u005Cd+)([._]\u005Cd+|)|Opera Mini|Android:?[ \u002F-](1{2}[4-9]|1[2-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})(\u005C.\u005Cd+|)(\u005C.\u005Cd+|)|Mobile Safari.+OPR\u005C\u002F(7[3-9]|[89]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+\u005C.\u005Cd+|Android.+Firefox\u005C\u002F(1{2}[3-9]|1[2-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Android.+Chrom(ium|e)\u005C\u002F(1{2}[4-9]|1[2-9]\u005Cd|[2-9]\u005Cd{2}|\u005Cd{4,})\u005C.\u005Cd+(\u005C.\u005Cd+|)|Android.+(UC? ?Browser|UCWEB|U3)[ \u002F]?(13\u005C.([4-9]|\u005Cd{2,})|(1[4-9]|[2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+)\u005C.\u005Cd+|SamsungBrowser\u005C\u002F([2-9]\u005Cd|\u005Cd{3,})\u005C.\u005Cd+|Android.+MQ{2}Browser\u005C\u002F(13(\u005C.([1-9]|\u005Cd{2,})|)|(1[4-9]|[2-9]\u005Cd|\u005Cd{3,})(\u005C.\u005Cd+|))(\u005C.\u005Cd+|)|K[Aa][Ii]OS\u005C\u002F(2\u005C.([5-9]|\u005Cd{2,})|([3-9]|\u005Cd{2,})\u005C.\u005Cd+)(\u005C.\u005Cd+|)", "")}),
  loader: () => undefined,
  targetFormats: ["webp","jpg|jpeg|png|gif"]
});

const fontList = new FontList([{"family":"Bebas Neue","locals":["Bebas Neue"],"fallback":["sans-serif"],"variances":[{"style":"normal","weight":300,"sources":[{"src":"assets/fonts/BebasNeue-Light.woff2","type":"woff2"}],"src":require("assets/fonts/BebasNeue-Light.woff2"),"type":"woff2"},{"style":"normal","weight":400,"sources":[{"src":"assets/fonts/BebasNeue-Regular.woff2","type":"woff2"}],"src":require("assets/fonts/BebasNeue-Regular.woff2"),"type":"woff2"},{"style":"normal","weight":700,"sources":[{"src":"assets/fonts/BebasNeue-Bold.woff2","type":"woff2"}],"src":require("assets/fonts/BebasNeue-Bold.woff2"),"type":"woff2"}]},{"family":"Lato","locals":["Lato"],"fallback":["sans-serif"],"variances":[{"style":"normal","weight":300,"sources":[{"src":"assets/fonts/Lato-Light.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-Light.woff2"),"type":"woff2"},{"style":"normal","weight":400,"sources":[{"src":"assets/fonts/Lato-Regular.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-Regular.woff2"),"type":"woff2"},{"style":"normal","weight":700,"sources":[{"src":"assets/fonts/Lato-Bold.woff2","type":"woff2"}],"src":require("assets/fonts/Lato-Bold.woff2"),"type":"woff2"}]}]);

globals.getImageSize = async (src) => {
  const { width, height } =await new Promise((resolve) => {
    const img = new window.Image();
    img.onload = () => resolve({width: img.naturalWidth, height: img.naturalHeight});
    img.src = src;
  });

  return {width, height};
};

export default (context, inject) => {
  inject('getFont', fontList.getFont.bind(fontList));
  inject('booster', booster);

  // For each render an own critical font style map is generated, which is used initially during page loading.
  const criticalFontStyles = {};
  inject('addCriticalFontStyle', (style) => {
    criticalFontStyles[style.hid] = style;
  });
  if (process.static && process.server) {
    context.beforeNuxtRender(({ nuxtState }) => {
      const ssrData = nuxtState.data[0] || {}
      ssrData._criticalFontStyles = criticalFontStyles
    })
  }
}

!('$booster' in Vue.prototype) && Object.defineProperty(Vue.prototype, '$booster', {
  get () {
    return Object.freeze(Object.assign({}, booster, {head: head.bind(this)}));
  }
});
