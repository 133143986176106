export default ({ app, $auth, req }) => { // Questo plugin perché la redirect automatica dopo il login dovuto a un URL sotto auth non va con il multilingua
  $auth.onRedirect((to) => {
    const isMobile = process.server
      ? /mobile/i.test(req.headers['user-agent'])
      : /mobile/i.test(navigator.userAgent)
    if (to === 'user-settings-applications' && isMobile) {
      to = 'user-settings'
    }
    return app.localePath(to)
  })
}
