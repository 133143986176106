var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "layout"
  }, [_c('header', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('nuxt-link', {
    staticClass: "header__logo click_logo",
    attrs: {
      "to": _vm.localePath('index')
    }
  }, [_c('img', {
    attrs: {
      "src": require("assets/images/logo.svg"),
      "width": "80",
      "height": "28",
      "alt": "CVing Logo",
      "title": "CVing Logo",
      "loading": "lazy"
    }
  })])], 1)]), _vm._v(" "), _c('Nuxt'), _vm._v(" "), _c('Footer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }