export const Application = () => import('../../components/blocks/Application.vue' /* webpackChunkName: "components/application" */).then(c => wrapFunctional(c.default || c))
export const Banner = () => import('../../components/blocks/Banner.vue' /* webpackChunkName: "components/banner" */).then(c => wrapFunctional(c.default || c))
export const Company = () => import('../../components/blocks/Company.vue' /* webpackChunkName: "components/company" */).then(c => wrapFunctional(c.default || c))
export const Event = () => import('../../components/blocks/Event.vue' /* webpackChunkName: "components/event" */).then(c => wrapFunctional(c.default || c))
export const Job = () => import('../../components/blocks/Job.vue' /* webpackChunkName: "components/job" */).then(c => wrapFunctional(c.default || c))
export const LaunchBox = () => import('../../components/blocks/LaunchBox.vue' /* webpackChunkName: "components/launch-box" */).then(c => wrapFunctional(c.default || c))
export const News = () => import('../../components/blocks/News.vue' /* webpackChunkName: "components/news" */).then(c => wrapFunctional(c.default || c))
export const Opportunity = () => import('../../components/blocks/Opportunity.vue' /* webpackChunkName: "components/opportunity" */).then(c => wrapFunctional(c.default || c))
export const Social = () => import('../../components/blocks/Social.vue' /* webpackChunkName: "components/social" */).then(c => wrapFunctional(c.default || c))
export const Special = () => import('../../components/blocks/Special.vue' /* webpackChunkName: "components/special" */).then(c => wrapFunctional(c.default || c))
export const University = () => import('../../components/blocks/University.vue' /* webpackChunkName: "components/university" */).then(c => wrapFunctional(c.default || c))
export const VideoPill = () => import('../../components/blocks/VideoPill.vue' /* webpackChunkName: "components/video-pill" */).then(c => wrapFunctional(c.default || c))
export const VideoSector = () => import('../../components/blocks/VideoSector.vue' /* webpackChunkName: "components/video-sector" */).then(c => wrapFunctional(c.default || c))
export const SsrCarousel = () => import('../../node_modules/vue-ssr-carousel/index.js' /* webpackChunkName: "components/ssr-carousel" */).then(c => wrapFunctional(c.default || c))
export const Btn = () => import('../../components/Btn.vue' /* webpackChunkName: "components/btn" */).then(c => wrapFunctional(c.default || c))
export const ChooseCv = () => import('../../components/ChooseCv.vue' /* webpackChunkName: "components/choose-cv" */).then(c => wrapFunctional(c.default || c))
export const Countries = () => import('../../components/Countries.vue' /* webpackChunkName: "components/countries" */).then(c => wrapFunctional(c.default || c))
export const CustomCalendar = () => import('../../components/CustomCalendar.vue' /* webpackChunkName: "components/custom-calendar" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const LoadingBar = () => import('../../components/LoadingBar.vue' /* webpackChunkName: "components/loading-bar" */).then(c => wrapFunctional(c.default || c))
export const Menu = () => import('../../components/Menu.vue' /* webpackChunkName: "components/menu" */).then(c => wrapFunctional(c.default || c))
export const Popup = () => import('../../components/Popup.vue' /* webpackChunkName: "components/popup" */).then(c => wrapFunctional(c.default || c))
export const Province = () => import('../../components/Province.vue' /* webpackChunkName: "components/province" */).then(c => wrapFunctional(c.default || c))
export const RadarFilters = () => import('../../components/RadarFilters.vue' /* webpackChunkName: "components/radar-filters" */).then(c => wrapFunctional(c.default || c))
export const RadarList = () => import('../../components/RadarList.vue' /* webpackChunkName: "components/radar-list" */).then(c => wrapFunctional(c.default || c))
export const Region = () => import('../../components/Region.vue' /* webpackChunkName: "components/region" */).then(c => wrapFunctional(c.default || c))
export const SmartCV = () => import('../../components/SmartCV.vue' /* webpackChunkName: "components/smart-c-v" */).then(c => wrapFunctional(c.default || c))
export const SuggestRules = () => import('../../components/SuggestRules.vue' /* webpackChunkName: "components/suggest-rules" */).then(c => wrapFunctional(c.default || c))
export const IconsCurriculumVitae = () => import('../../components/icons/CurriculumVitae.vue' /* webpackChunkName: "components/icons-curriculum-vitae" */).then(c => wrapFunctional(c.default || c))
export const IconsPersonalityTest = () => import('../../components/icons/PersonalityTest.vue' /* webpackChunkName: "components/icons-personality-test" */).then(c => wrapFunctional(c.default || c))
export const IconsPlay = () => import('../../components/icons/Play.vue' /* webpackChunkName: "components/icons-play" */).then(c => wrapFunctional(c.default || c))
export const ModalsErrorModal = () => import('../../components/modals/ErrorModal.vue' /* webpackChunkName: "components/modals-error-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsModal = () => import('../../components/modals/Modal.vue' /* webpackChunkName: "components/modals-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsOrpModal = () => import('../../components/modals/OrpModal.vue' /* webpackChunkName: "components/modals-orp-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalsReloadModal = () => import('../../components/modals/ReloadModal.vue' /* webpackChunkName: "components/modals-reload-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
